<template>
  <CCard body-wrapper>
    <CRow
      align-vertical="center"
      class="mb-3"
    >
      <CCol col="auto">
        <h1>
          Чеки
        </h1>
      </CCol>
      <CCol col="auto">
        <template v-if="filtersVisible">
          <template v-if="filtersSet">
            <CButton
              color="warning"
              @click="filtersVisible = !filtersVisible"
            >
              Фильтр
            </CButton>
          </template>
          <template v-else>
            <CButton
              color="dark"
              @click="filtersVisible = !filtersVisible"
            >
              Фильтр
            </CButton>
          </template>
        </template>
        <template v-else>
          <template v-if="filtersSet">
            <CButton
              color="warning"
              variant="outline"
              @click="filtersVisible = !filtersVisible"
            >
              Фильтр
            </CButton>
          </template>
          <template v-else>
            <CButton
              color="dark"
              variant="outline"
              @click="filtersVisible = !filtersVisible"
            >
              Фильтр
            </CButton>
          </template>
        </template>
      </CCol>
      <template v-if="loadingOrders">
        <CCol col="auto">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          <span class="sr-only">"Пожалуйста, подождите...</span>
        </CCol>
      </template>
      <template v-else>
        <CCol col="auto">
          Количество: <span
            class="font-weight-bold"
          >{{ getLocalizedNumber(total.orders) }}</span>
        </CCol>
        <CCol col="auto">
          Сумма: <span
            class="font-weight-bold"
          >{{ getLocalizedAmount(total.amount) }}</span>
        </CCol>
      </template>
    </CRow>
    <CForm
      v-show="filtersVisible"
      class="filters mb-3 p-3"
      @submit.prevent="find"
    >
      <CRow align-vertical="start">
        <CCol
          xs="12"
          xl="3"
        >
          <div class="filters__filter">
            <CButton
              class="filters__filter-button"
              color="primary"
              type="submit"
            >
              <template v-if="loadingOrders">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                <span class="sr-only">Пожалуйста, подождите...</span>
              </template>
              <template v-else>
                Найти
              </template>
            </CButton>
            <CButton
              class="filters__filter-button ml-2"
              color="secondary"
              @click="clearFilters"
            >
              Очистить
            </CButton>
          </div>
        </CCol>
        <CCol
          xs="12"
          xl="9"
        >
          <CRow class="mb-1">
            <CCol
              xs="12"
              xl="2"
            >
              <div
                class="filters__filter"
                :class="{ 'filters__filter--active': filters.external_order_id !== '' }"
              >
                <CInput
                  label="Номер чека"
                  :value.sync="filters.external_order_id"
                />
              </div>
            </CCol>
            <CCol
              xs="12"
              xl="3"
            >
              <div
                class="filters__filter"
                :class="{ 'filters__filter--active': filters.order_type !== '' }"
              >
                <CSelect
                  label="Тип чека"
                  :options="[ { value: null, label: 'Все' },  { value: 'sell', label: 'Оплата' }, { value: 'sell_refund', label: 'Возврат' } ]"
                  :value.sync="filters.order_type"
                />
              </div>
            </CCol>
            <CCol
              xs="12"
              xl="4"
            >
              <div
                class="filters__filter"
                :class="{
              'filters__filter--active':
                filters.created_at_from !== '' || filters.created_at_to !== ''
            }"
              >
                <CRow>
                  <CCol col="12">
                    <label for="createdAtFrom">Дата создания</label>
                  </CCol>
                </CRow>
                <CRow
                  align-vertical="center"
                  :gutters="false"
                >
                  <CCol>
                    <v-date-picker
                      v-model="filters.created_at_from"
                      :attributes="[{ dot: true, dates: new Date(), key: 'today' }]"
                      locale="ru"
                      @input="
                    filters.created_at_from =
                      $event
                        ? locale.format($event, 'YYYY-MM-DD')
                        : null
                  "
                    >
                      <template #default="{ inputValue, togglePopover }">
                        <CRow
                          align-vertical="center"
                          :gutters="false"
                        >
                          <CCol>
                            <CInput
                              id="createdAtFrom"
                              :lazy="800"
                              :readonly="true"
                              :value="inputValue"
                              @click="togglePopover({ placement: 'bottom-start' })"
                              @update:value="filters.created_at_from = formatDate($event)"
                            >
                              <template #prepend-content>
                                <CIcon name="cil-calendar" />
                              </template>
                            </CInput>
                          </CCol>
                          <CCol
                            v-if="filters.created_at_from !== ''"
                            col="1"
                            class="text-center"
                          >
                            <CLink @click="filters.created_at_from = ''">
                              <CIcon name="cil-x" />
                            </CLink>
                          </CCol>
                        </CRow>
                      </template>
                    </v-date-picker>
                  </CCol>
                  <CCol
                    col="auto"
                    class="pl-1 pr-1 text-center"
                  >
                    &mdash;
                  </CCol>
                  <CCol>
                    <v-date-picker
                      v-model="filters.created_at_to"
                      :attributes="[{ dot: true, dates: new Date(), key: 'today' }]"
                      locale="ru"
                      @input="
                    filters.created_at_to =
                      $event
                        ? locale.format($event, 'YYYY-MM-DD')
                        : null
                  "
                    >
                      <template #default="{ inputValue, togglePopover }">
                        <CRow
                          align-vertical="center"
                          :gutters="false"
                        >
                          <CCol>
                            <CInput
                              id="createdAtTo"
                              :lazy="800"
                              :readonly="true"
                              :value="inputValue"
                              @click="togglePopover({ placement: 'bottom-start' })"
                              @update:value="filters.created_at_to = formatDate($event)"
                            >
                              <template #prepend-content>
                                <CIcon name="cil-calendar" />
                              </template>
                            </CInput>
                          </CCol>
                          <CCol
                            v-if="filters.created_at_to !== ''"
                            col="1"
                            class="text-center"
                          >
                            <CLink @click="filters.created_at_to = ''">
                              <CIcon name="cil-x" />
                            </CLink>
                          </CCol>
                        </CRow>
                      </template>
                    </v-date-picker>
                  </CCol>
                </CRow>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol
              xs="12"
              xl="3"
            >
              <div
                class="filters__filter"
                :class="{ 'filters__filter--active': filters.status !== '' }"
              >
                <CSelect
                  label="Статус"
                  :options="[ { value: null, label: 'Все' },  { value: 'wait', label: 'В очереди OK' },  { value: 'pending', label: 'В очереди ЕКАМ' },  { value: 'printed', label: 'Напечатан' }, { value: 'error', label: 'Ошибка' } ]"
                  :value.sync="filters.status"
                />
              </div>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CForm>
    <CDataTable
      class="mt-5"
      :fields="tableFields"
      :header="true"
      :hover="true"
      :items="orders"
      :loading="loadingOrders"
      :striped="true"
    >
      <template #amount="{ item }">
        <td class="align-middle text-center">
          {{ getLocalizedAmount(item.amount) }}
        </td>
      </template>
      <template #orderType="{ item }">
        <td :class="getColoredClass('align-middle text-center', item.orderType)">
          {{ item.orderType === 'sell' ? 'Оплата' : 'Возврат' }}
        </td>
      </template>
      <template #sendedAt="{ item }">
        <td class="align-middle text-center">
          <template v-if="item.sendedAt !== null">
            {{ getLocalizedDate(item.sendedAt) }} {{ getLocalizedTime(item.sendedAt) }}
          </template>
          <template v-else>
            &mdash;
          </template>
        </td>
      </template>
      <template #createdAt="{ item }">
        <td class="align-middle text-center">
          {{ getLocalizedDate(item.createdAt) }} {{ getLocalizedTime(item.createdAt) }}
        </td>
      </template>
      <template #loading>
        <CElementCover
          :opacity="0.8"
        >
          <CSpinner
            class="custom-spinner"
            color="info"
          />
        </CElementCover>
      </template>
      <template #no-items-view>
        <div class="text-center">
          Отсутствуют чеки
        </div>
      </template>
      <template #externalOrderId="{ item }">
        <td class="align-middle text-center">
          {{ item.externalOrderId }}
        </td>
      </template>
      <template #status="{ item }">
        <td class="align-middle text-center">
          <template v-if="item.status === 'Ошибка' && item.errorDescription !== null">
            <span
              v-c-popover="{header: 'Чек № ' + item.externalOrderId, content: item.errorDescription, placement: 'left'}"
              class="text-color-red error-link-underline"
            >
              {{ item.status }}
            </span>
          </template>
          <template v-else>
            {{ item.status }}
          </template>
        </td>
      </template>
      <template #receiptUrl="{ item }">
        <template v-if="item.receiptUrl !== null">
          <td class="align-middle text-center">
            <CLink
              :href="item.receiptUrl"
              target="_blank"
              title="Ссылка на чек"
            >
              <CIcon name="cil-external-link" />
            </CLink>
          </td>
        </template>
        <template v-else-if="item.status === 'Ошибка'">
          <td class="align-middle text-center">
            <CButton
              class="btn-primary btn-sm mb-1 w-75"
              @click="checkEkamStatus(item)"
              :disabled = "item.loadingStatus"
              >
                <template v-if="item.loadingStatus">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span class="sr-only">Пожалуйста, подождите...</span>
                </template>
                <template v-else>
                  Проверить статус
                </template>
            </CButton>
            <br>
            <CButton
              class="btn-danger btn-sm w-75"
              @click="sendOrderToEkam(item)"
              :disabled = "item.loadingStatus"
            >
              <template v-if="item.loadingStatus">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                <span class="sr-only">Пожалуйста, подождите...</span>
              </template>
              <template v-else>
                Повторить отправку
              </template>
            </CButton>
          </td>
        </template>
        <template v-else>
          <td></td>
        </template>
      </template>
    </CDataTable>
    <CPagination
      v-if="pagesCount > 1"
      :active-page.sync="currentPage"
      :pages="pagesCount"
      align="start"
      @update:activePage="getOrders"
    />
    <div class="export-request-completed-alert">
      <CAlert
        color="danger"
        closeButton
        :show.sync="dangerAlertCounter"
        class="position-sticky"
        @close="successAlert"
      >
        {{dangerAlertContent}}
        <CProgress
          :max="10"
          :value="dangerAlertCounter"
          height="3px"
          color="danger"
          hidden
        />
      </CAlert>
      <CAlert
        color="success"
        closeButton
        :show.sync="successAlertCounter"
        class="position-sticky"
      >
        {{successAlertContent}}
        <CProgress
          :max="10"
          :value="successAlertCounter"
          height="3px"
          color="success"
          hidden
        />
      </CAlert>
    </div>
  </CCard>

</template>

<script>
import Swal from 'sweetalert2';
import AmountMixins from '../../../mixins/AmountMixins';
import DateTimeMixins from '../../../mixins/DateTimeMixins';
import ListMixins from '../../../mixins/ListMixins';
import NumberMixins from '../../../mixins/NumberMixins';
import urls from '../../../router/urls';

export default {
  name: 'List',

  mixins: [
    AmountMixins,
    DateTimeMixins,
    ListMixins,
    NumberMixins,
  ],

  data() {
    return {
      actionIsBeingPerformed: false,
      filters: {},
      loadingOrders: false,
      orders: [],
      dangerAlertCounter: 0,
      successAlertCounter: 0,
      dangerAlertContent: '',
      successAlertContent: '',
      performAndActionWithAll: false,
      tableFields: [
        {
          _classes: ['align-middle', 'text-center'],
          key: 'externalOrderId',
          label: '№',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'orderType',
          label: 'Тип',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'amount',
          label: 'Сумма, руб',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'createdAt',
          label: 'Дата создания',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'sendedAt',
          label: 'Дата отправки в ЕКАМ',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'status',
          label: 'Статус',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'receiptUrl',
          label: 'Чек',
        },
      ],
      total: {
        amount: 0,
        orders: 0,
      },
    };
  },

  created() {
    this.initFilters();
    this.setFiltersFromUrl();

    this.getOrders();
  },
  methods: {
    async clearFilters() {
      this.initFilters();

      await this.find();
    },
    async find() {
      this.currentPage = 1;

      await this.getOrders();
    },
    async checkEkamStatus(item) {

      let externalOrderId = item.externalOrderId;

      let indexof = this.orders.indexOf(item);
      item.loadingStatus = true;
      Object.assign(this.orders[indexof], item);

      axios.put('/api/orders/' + externalOrderId + '/update')
        .then(response => {

          item.loadingStatus = false;

          Object.assign(this.orders[indexof], item);

          if (response.data.responseCode !== 200) {
            this.dangerAlertContent = response.data.message;
            this.dangerAlertCounter = 10;
          } else {
            this.successAlertContent = response.data.message;
            this.successAlertCounter = 10;
          }

          if (response.data.needReload) {
            this.getOrders();
          }
        })
        .catch(error => {
          this.dangerAlertContent = 'При получении статуса чека возникла ошибка. Пожалуйста, повторите попытку позднее. При повторении ошибки сообщите разработчику';

          if (error.response.data.message !== undefined) {
            this.dangerAlertContent = error.response.data.message;
          }
          this.dangerAlertCounter = 10;

          console.warn(error.response)

          item.loadingStatus = false;

          Object.assign(this.orders[indexof], item);
        })
    },
    async sendOrderToEkam(item) {
      let externalOrderId = item.externalOrderId;

      let indexof = this.orders.indexOf(item);
      item.loadingStatus = true;
      Object.assign(this.orders[indexof], item);

      let data = {
        externalOrderId: externalOrderId,
      };
      axios.post('/api/send-order-to-ekam', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          if (response.data.needReload) {
            this.getOrders();
          }
          item.loadingStatus = false;

          Object.assign(this.orders[indexof], item);

          this.successAlertContent = response.data.message;
          this.successAlertCounter = 10;

        })
        .catch(error => {
          this.dangerAlertContent = 'При отправке чека возникла ошибка. Пожалуйста, повторите попытку позднее. При повторении ошибки сообщите разработчику';

          if (error.response.data.message !== undefined) {
            this.dangerAlertContent = error.response.data.message;
          }
          this.dangerAlertCounter = 10;

          console.warn(error.response)

          item.loadingStatus = false;

          Object.assign(this.orders[indexof], item);
        })
    },
    async getOrders() {
      this.loadingOrders = true;

      try {
        const queryString = this.getFiltersAsQueryString();

        const { data } = await this.$axios.get(urls.urlGetOrders + queryString);

        this.currentPage = data.metadata.page_num;

        this.orders = [];

        data.orders.forEach(({
          amount,
          order_type: orderType,
          created_at: createdAt,
          sended_at: sendedAt,
          external_order_id: externalOrderId,
          status: status,
          receipt_url: receiptUrl,
          error_description: errorDescription,
          loadingStatus: loadingStatus
        }) => {
          this.orders.push({
            amount,
            orderType,
            createdAt,
            sendedAt,
            externalOrderId,
            status,
            receiptUrl,
            errorDescription,
            loadingStatus: false
          });
        });

        this.pagesCount = data.metadata.total_pages;

        this.total.amount = data.metadata.total_amount;
        this.total.orders = data.metadata.total_orders;

        const url = `${this.$route.path}${queryString}`;

        window.history.pushState({ url }, '', url);
      } catch (e) {
        if (e.response) {
          this.successAlertContent = 'При загрузке чеков возникла ошибка. Пожалуйста, повторите попытку позднее. При повторении ошибки сообщите разработчику';
          this.successAlertCounter = 10;
        }
      } finally {
        this.loadingOrders = false;
      }
    },

    getColoredClass(classes, orderType) {
      return orderType !== 'sell' ? classes + ' text-color-red' : classes;
    },

    initFilters() {
      this.filters = {
        created_at_from: '',
        created_at_to: '',
        external_order_id: '',
        order_type: '',
        status: ''
      };
    },
  },
};
</script>

<style scoped>

</style>
